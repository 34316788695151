.container {
    @apply
    container-medium
    container-gutters-small
    md:container-gutters-medium
    mx-auto;
}

.contactLink {
    @apply
    flex
    items-center
    gap-100
    mt-100
    type-link-reverse;
}

.trustSection {
    @apply
    container-large
    container-gutters-small
    mx-auto
    md:container-gutters-medium
    flex
    flex-col
    gap-150
    items-center
    mt-200
    md:mt-300 
    lg:mt-400;
}

.attentionSection {
    @apply
    pb-200
    container-medium
    mx-auto
    space-y-200
    container-gutters-small
    md:container-gutters-medium
    md:pb-300
    lg:pb-400;
}