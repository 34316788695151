.reel {
    @media (min-width: 769px) {
        display: grid;
    }

        & ul {
        @media (min-width: theme('screens.md')) {
            @apply grid-cols-3;

            display: grid;
            gap: theme('spacing.075');
            width: 100%;
        }
    }

    & > * {
        @media (min-width: 769px) {
            width: auto;
        }
    }
}
