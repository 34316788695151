.description {
    display: flex;
    gap: theme('spacing.100');
    padding: theme('spacing.075');
}

.metadata {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: theme('spacing.050');
    padding: theme('spacing.075');
    border-top: 1px solid theme('colors.decorators.subtle');
}

.img {
    flex-shrink: 0;
    width: theme('sizes.300');
    height: theme('sizes.300');
}
