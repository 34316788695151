.deliveryContent {
    @apply
    grid
    grid-cols-1
    gap-300
    lg:gap-400
    lg:grid-cols-2
    container-large
    container-gutters-small 
    md:container-gutters-medium
    mx-auto;
}

.deliveryListItem {
    @apply
    flex
    justify-between
    flex-1
    pb-100
    md:space-y-100
    md:basis-1/4
    lg:basis-1/6;

    border-bottom: theme('border.light');

    &:first-child {
        @apply md:col-span-2 lg:col-span-1;    
    }

    &:last-child {
        border-bottom: none;

        @media (min-width: theme('screens.md')) {
            border-bottom: theme("border.light");
        }
        
        @media (min-width: theme('screens.lg')) {
            border-bottom: none
        }
    }
}

.deliveryInformation {
    @apply
    container-large
    mx-auto
    container-gutters-small
    md:container-gutters-medium
    my-rhythm-medium
    lg:my-rhythm-large;
}

.deliveryFaqTitle {
    @apply
    type-subtitle
    text-500
    md:text-600
    lg:text-700
    mx-auto;
}