.links {
    @apply
    flex
    flex-col
    gap-100
    type-link-reverse
    type-090
    md:flex-row
    md:justify-between
    lg:flex-col;
}